import React from "react"
import { CandidateTest_NoteFragment } from "~/__generated__/graphql"
import { formatDateAndTime } from "~/common/dates"
import { scoreLabel } from "~/questions/ManualScoreDialog"

export const CandidateTestNotesList = ({
  notes,
}: {
  notes: CandidateTest_NoteFragment[]
}) => {
  return (
    notes.length > 0 && (
      <div className="flex flex-col gap-6 pb-8">
        <h2 className="text-lg text-gray-333">Internal Feedback</h2>
        <div className="grid grid-cols-12 gap-6">
          {notes.map((note) => (
            <React.Fragment key={note.id}>
              <div className="col-span-2 flex flex-col gap-2">
                <div className="text-sm text-gray-333 flex justify-between gap-2">
                  <div>{note.user.name}</div>
                  <div className="min-width-4 text-justify">
                    {note.manualScore && scoreLabel(note.manualScore)}
                  </div>
                </div>
                <div className="text-xs text-gray-999">
                  {formatDateAndTime(note.createdAt)}
                </div>
              </div>
              <div className="col-span-10 text-sm text-gray-333">
                {note.text}
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    )
  )
}

import { useOrganizationTest } from "~/common/useOrganizationTest"
import { useQuery } from "@apollo/client"
import { useParams, useNavigate } from "react-router-dom"
import invariant from "tiny-invariant"
import { LoadingIndicatorCentered } from "~/components/LoadingIndicator"
import { EmptyData } from "~/components/EmptyData"
import { Error } from "~/ui/Error"
import { CandidateTestLayout } from "~/layouts/CandidateTestLayout"
import {
  organizationQuestionResponsePath,
  organizationTestResponsesPath,
  organizationCandidatesPath,
} from "~/common/paths"
import { useOrganizationId } from "~/common/useCurrentOrganization"
import { TestQuestionResponsesList } from "~/tests/TestQuestionResponsesList"
import { Navigation } from "~/organizations/Navigation"
import { CANDIDATE_TEST_RESPONSES_QUERY_DOCUMENT } from "~/tests/candidateTestQueries"
import { ShareButton } from "~/tests/ShareUI"
import { EmailCandidateDialog } from "~/organizations/EmailCandidateDialog"
import { CandidateTestNoteDialog } from "~/organizations/CandidateTestNoteDialog"
import { CandidateTestNotesList } from "~/organizations/CandidateTestNotesList"
import { ToggleArchivedButton } from "~/tests/ToggleArchivedAtButton"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "~/shadcn/ui/tabs"
import { useState } from "react"

export const OrganizationTestResponseScreen = () => {
  const { test } = useOrganizationTest()
  const { candidateTestId } = useParams()
  invariant(candidateTestId)
  const navigate = useNavigate()

  const { loading, data, error } = useQuery(
    CANDIDATE_TEST_RESPONSES_QUERY_DOCUMENT,
    {
      variables: { candidateTestId },
    }
  )

  const [selectedTab, setSelectedTab] = useState<string>("summary")

  const organizationId = useOrganizationId()
  const testId = test?.id || ""

  const onArchived = () => {
    navigate(organizationCandidatesPath({ organizationId }))
  }

  return (
    <CandidateTestLayout
      test={test}
      candidateTest={data?.candidateTest}
      backLabel="Responses"
      backPath={organizationTestResponsesPath({ organizationId, testId })}
      navigation={
        <Navigation organizationId={organizationId} testId={testId} />
      }
      rightProfile={
        <div className="flex items-center gap-2">
          <EmailCandidateDialog candidateTestId={candidateTestId} />
          <CandidateTestNoteDialog
            candidateTestId={candidateTestId}
            onTabChange={() => setSelectedTab("discussion")}
          />
          {data && (
            <>
              <ToggleArchivedButton
                candidateTest={data?.candidateTest}
                showIcon
                onChange={onArchived}
              />
              <ShareButton candidateTestId={data.candidateTest.id} />
            </>
          )}
        </div>
      }
    >
      {error ? <Error message="Error loading responses." /> : null}
      {loading ? (
        <LoadingIndicatorCentered />
      ) : data && data.candidateTestQuestionResponses.nodes.length > 0 ? (
        <>
          <Tabs
            value={selectedTab}
            onValueChange={setSelectedTab}
            className="w-full"
          >
            <TabsList className="w-full justify-start rounded-none border-b bg-transparent p-0">
              <TabsTrigger
                value="summary"
                className="relative h-9 rounded-none border-b-2 border-b-transparent bg-transparent px-4 pb-3 pt-2 font-semibold text-muted-foreground shadow-none transition-none data-[state=active]:border-b-primary data-[state=active]:text-foreground data-[state=active]:shadow-none"
              >
                Test Summary
              </TabsTrigger>
              <TabsTrigger
                value="discussion"
                className="relative h-9 rounded-none border-b-2 border-b-transparent bg-transparent px-4 pb-3 pt-2 font-semibold text-muted-foreground shadow-none transition-none data-[state=active]:border-b-primary data-[state=active]:text-foreground data-[state=active]:shadow-none"
              >
                Discussion
                {data.candidateTest.notes.length > 0 && (
                  <div className="ml-2 text-white text-xs w-6 h-6 rounded-full bg-primary flex items-center justify-center text-[8px]">
                    {data.candidateTest.notes.length}
                  </div>
                )}
              </TabsTrigger>
            </TabsList>
            <TabsContent
              value="summary"
              className="pt-4 w-full flex flex-column"
            >
              <TestQuestionResponsesList
                data={data.candidateTestQuestionResponses.nodes}
                viewPath={(response) =>
                  organizationQuestionResponsePath({
                    organizationId,
                    testId,
                    questionResponseId: response.id,
                  })
                }
                onlyAnswered
              />
            </TabsContent>
            <TabsContent
              value="discussion"
              className="pt-4 w-full flex flex-column p-2 border border-gray-E6E6E3 rounded"
            >
              <CandidateTestNotesList notes={data?.candidateTest.notes} />
            </TabsContent>
          </Tabs>
        </>
      ) : (
        <EmptyData>No responses yet.</EmptyData>
      )}
    </CandidateTestLayout>
  )
}
